<template>
  <div>
    <div class="main-container">
      <div class="row-panel search-panel">
        <el-input
          v-model.trim="searchParams.skey"
          class="search-item"
          style="width: 260px;"
          placeholder="视频ID、视频名称"
          size="mini"
          clearable
          @clear="queryvList"
          @keyup.enter.native="searchInit"
        />
        <z-button
          type="primary"
          size="medium"
          class="search-item"
          @click="searchInit"
        >
          查询
        </z-button>
        <div class="search-item">
          <span class="search-label">发布日期</span>
          <el-date-picker
            v-model="searchParams.beginAndEndTime"
            size="mini"
            type="daterange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            @change="fetchvideoList"
          />
        </div>
      </div>
      <div class="row-panel relative">
        <div
          class="release_btn"
          @click="openrevideo()"
        >
          <z-button
            type="primary"
            size="medium"
          >
            <i class="iconfont fabuhuodong" />发布视频
          </z-button>
        </div>
        <el-tabs
          v-model="searchParams.nstatus"
          @tab-click="changeTab"
        >
          <el-tab-pane
            v-for="(item,index) in tabStatus"
            :ref="'tab' + item.value"
            :key="index"
            :label="item.text"
            :name="item.value"
          />
        </el-tabs>
        <div
          v-loading="loading"
          style="overflow-x: auto;"
        >
          <div
            v-if="searchParams.nstatus==1"
            class="v-content"
          >
            <table class="itxst">
              <thead>
                <tr>
                  <th width="49px" />
                  <th width="48px">
                    排序
                  </th>
                  <th width="81px">
                    视频ID
                  </th>
                  <th width="398px">
                    视频名称
                  </th>
                  <th width="137px">
                    封面图
                  </th>
                  <th width="137px">
                    观看量
                  </th>
                  <th width="137px">
                    视频时长
                  </th>
                  <th width="138px">
                    视频大小
                  </th>
                  <th width="138px">
                    视频格式
                  </th>
                  <th width="161px">
                    发布时间
                  </th>
                  <th width="194px">
                    操作
                  </th>
                </tr>
              </thead>
              <tbody
                is="draggable"
                v-if="videoList.length>=1"
                v-model="videoList"
                animation="500"
                force-fallback="true"
                handle=".move"
                :move="checkMove"
                element="tbody"
                @start="onStart"
                @end="onEnd"
              >
                <tr
                  v-for="(item ,index) in videoList"
                  :key="index"
                >
                  <td class="move">
                    <p>
                      <img
                        src="../../assets/images/video/drag.png"
                        class="dragsize"
                      >
                    </p>
                  </td>
                  <td>
                    <p>
                      {{ item.nsortId }}
                    </p>
                  </td>
                  <td>
                    <p>
                      <copy-text
                        :copy-text="String(item.nvideoId)"
                      />
                    </p>
                  </td>
                  <td>
                    <p>
                      <copy-text :copy-text="item.svideoName" />
                    </p>
                  </td>
                  <td>
                    <p>
                      <img
                        class="vimg"
                        :src="item.scoverImgUrl"
                      >
                    </p>
                  </td>
                  <td>
                    <p>
                      {{ item.nviewNum }}
                    </p>
                  </td>
                  <td>
                    <p>
                      {{ item.svideoDuration }}
                    </p>
                  </td>
                  <td>
                    <p>
                      {{ item.svideoSize }}
                    </p>
                  </td>
                  <td>
                    <p>
                      {{ item.svideoFormat }}
                    </p>
                  </td>
                  <td>
                    <p>
                      {{ item.dpushTime }}
                    </p>
                  </td>
                  <td class="dash">
                    <z-button
                      type="text"
                      size="small"
                      :color="2"
                      font-size="small"
                      @click="openPaly(item)"
                    >
                      播放
                    </z-button>
                    <z-button
                      type="text"
                      size="small"
                      :color="2"
                      font-size="small"
                      @click="showEdit(item)"
                    >
                      修改
                    </z-button>
                    <z-button
                      type="text"
                      size="small"
                      :color="2"
                      font-size="small"
                      @click="openpop(item,0)"
                    >
                      下架
                    </z-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table v-if="videoList.length<1">
              <tbody class="no-data">
                暂无数据
              </tbody>
            </table>
          </div>
          <div
            v-if="searchParams.nstatus==0"
            class="v-content"
          >
            <el-table
              :data="videoList"
              stripe
              border
              style="width: 100%"
            >
              <el-table-column
                prop="nvideoId"
                label="视频ID"
                min-width="155"
              >
                <template slot-scope="scope">
                  <copy-text
                    :copy-text="String(scope.row.nvideoId)"
                  />
                </template>
              </el-table-column>
              <el-table-column
                prop="svideoName"
                label="视频名称"
                min-width="395"
              >
                <template slot-scope="scope">
                  <copy-text :copy-text="scope.row.svideoName" />
                </template>
              </el-table-column>
              <el-table-column
                prop="scoverImgUrl"
                label="封面图"
                min-width="137"
              >
                <template slot-scope="scope">
                  <img
                    class="vimg"
                    :src="scope.row.scoverImgUrl"
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="nviewNum"
                label="观看量"
                min-width="137"
              >
                <template slot-scope="scope">
                  {{ scope.row.nviewNum }}
                </template>
              </el-table-column>
              <el-table-column
                prop="svideoDuration"
                label="视频时长"
                min-width="137"
              >
                <template slot-scope="scope">
                  {{ scope.row.svideoDuration }}
                </template>
              </el-table-column>
              <el-table-column
                prop="svideoSize"
                label="视频大小"
                min-width="138"
              >
                <template slot-scope="scope">
                  {{ scope.row.svideoSize }}
                </template>
              </el-table-column>
              <el-table-column
                prop="svideoFormat "
                label="视频格式"
                min-width="138"
              >
                <template slot-scope="scope">
                  {{ scope.row.svideoFormat }}
                </template>
              </el-table-column>
              <el-table-column
                prop="dpushTime "
                label="发布时间 "
                min-width="161"
              >
                <template slot-scope="scope">
                  {{ scope.row.dpushTime }}
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                fixed="right"
                label="操作"
                min-width="200"
              >
                <template slot-scope="scope">
                  <z-button
                    type="text"
                    size="small"
                    :color="2"
                    font-size="small"
                    @click="visibleDialog=true"
                  >
                    播放
                  </z-button>
                  <z-button
                    type="text"
                    size="small"
                    :color="2"
                    font-size="small"
                    @click="showEdit(scope.row)"
                  >
                    修改
                  </z-button>
                  <z-button
                    type="text"
                    size="small"
                    :color="2"
                    font-size="small"
                    @click="openpop(scope.row,1)"
                  >
                    发布
                  </z-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              popper-class="paginationComCls"
              style="text-align:center;margin-top:20px"
              layout="sizes, prev, pager, next,total"
              :total="total"
              :page-sizes="[12, 20, 30, 40, 50]"
              :page-size="pageSize"
              :current-page="pageNum"
              @current-change="current_page_change"
              @size-change="handleSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
    <play-dialog
      v-if="visibleDialog"
      :visible.sync="visibleDialog"
      :play-param="playParam"
      @refreshList="refreshtable"
    />
    <release-dialog
      v-if="visibleDialog2"
      :visible.sync="visibleDialog2"
      :current-param="currentParam"
      @refreshList="successToEdit"
    />
    <warm-pop
      :pop="pop.ispop"
      :is-cancle="true"
      :close-img="true"
      :icon="-11"
      :title="pop.title"
      @close="close"
    >
      <z-button
        slot="btn"
        type="primary"
        @click="comfirmxj()"
      >
        确认
      </z-button>
    </warm-pop>
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import playDialog from './playDialog';
import releaseDialog from './releaseDialog';
import warmPop from '../../components/common/warmPop';
import {
  vList, offVideo, dragList, isshowadd,
} from '../../api/video';
import copyText from '../../components/common/copyText';
import { dealBeginTime, dealEndTime } from '../../../public/js/util';

export default {
  name: 'VideoManage',
  components: {
    draggable,
    playDialog,
    releaseDialog,
    warmPop,
    copyText,
  },
  data() {
    return {
      pop: {
        ispop: false,
        title: '',
      },
      // 搜索栏参数
      searchParams: {
        skey: '',
        beginAndEndTime: '',
        nstatus: '1',
      },
      createOrderDate: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
      tabStatus: [
        {
          text: '发布中',
          value: '1',
        },
        {
          text: '已下架',
          value: '0',
        },
      ],
      loading: false,
      // 拖拽
      drag: false,
      videoList: [],
      visibleDialog: false,
      visibleDialog2: false,
      pageNum: 1,
      pageSize: 12,
      total: 0,
      offparam: {}, // 上下架的参数
      currentParam: {}, // 修改视频参数
      playParam: {}, // 播放参数
    };
  },
  computed: {
    ...mapGetters(['brandInfo']),
    beginTime() {
      return this.searchParams.beginAndEndTime ? dealBeginTime(this.searchParams.beginAndEndTime[0]) : '';
    },
    endTime() {
      return this.searchParams.beginAndEndTime ? dealEndTime(this.searchParams.beginAndEndTime[1]) : '';
    },
  },
  watch: {
    'searchParams.nstatus': function (newName, oldName) {
      this.pageNum = 1;
      this.queryvList();
    },
    beginTime: {
      handler(newVal, oldVal) {
        console.log('时间', this.searchParams.beginAndEndTime);
      },
    },
    endTime: {
      handler(newVal, oldVal) {
        console.log('时间w', this.searchParams.beginAndEndTime);
      },
    },
  },
  mounted() {
    this.queryvList();
  },
  methods: {
    ctile(item, n) {
      if (item) {
        if (item.length <= n) {
          return item;
        }
        return `${item.substring(0, n)}...`;
      }
      return '';
    },
    checkMove(evt) {
      console.log(evt);
      return true;
    },
    openrevideo() {
      isshowadd(this.brandInfo.nbrandId).then((res) => {
        this.visibleDialog2 = true;
        this.currentParam = {};
      }).catch((e) => this.$message.error(e.message));
    },
    refreshtable() {
      this.visibleDialog = false;
      this.queryvList();
    },
    successToEdit() {
      this.visibleDialog2 = false;
      this.queryvList();
    },
    openPaly(param) {
      this.playParam = param;
      this.visibleDialog = true;
    },
    showEdit(param) {
      this.currentParam = param;
      this.visibleDialog2 = true;
    },
    current_page_change(param) {
      this.pageNum = param;
      this.queryvList();
    },
    handleSizeChange(param) {
      this.pageNum = 1;
      this.pageSize = param;
      this.queryvList();
    },
    searchInit() {
      this.pageNum = 1;
      this.queryvList();
    },
    fetchvideoList() {
      this.pageNum = 1;
      this.queryvList();
    },
    changeTab(tab) {
      const { name } = tab;
      this.searchParams.nstatus = name;
    },
    onStart() {
      this.drag = true;
    },
    onEnd() {
      debugger;
      this.drag = false;
      const temp = [];// 查看是否合并的数组
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.videoList.length; i++) {
        temp.push({
          nsortId: (i + 1),
          pkid: this.videoList[i].pkid,
        });
      }
      dragList(temp).then((res) => {
        console.log('拖拽', res);
        this.queryvList();
      }).catch((e) => this.$message.error('系统异常，请联系管理员'));
    },
    close() {
      this.pop.ispop = false;
      this.offparam = {};
    },
    openpop(item, index) {
      if (index === 0) {
        this.pop.title = '确定下架该视频？';
      } else {
        this.pop.title = '确定发布该视频？';
      }
      const param = {
        nstatus: index,
        pkid: item.pkid,
        nbrandId: this.brandInfo.nbrandId,
      };
      if (param) {
        this.offparam = param;
      }
      this.pop.ispop = true;
    },
    comfirmxj() {
      offVideo(this.offparam).then((res) => {
        if (res.code === 0) {
          this.$message.success(res.e.msg);
          this.close();
          this.queryvList();
        } else {
          this.$message.success('失败');
        }
      }).catch((e) => this.$message.error(e.message));
    },
    queryvList() {
      this.loading = true;
      const param = {
        dpushTimeBegin: this.beginTime,
        dpushTimeEnd: this.endTime,
        nstatus: parseInt(this.searchParams.nstatus, 10),
        searchKeyword: this.searchParams.skey,
      };
      if (parseInt(this.searchParams.nstatus, 10) === 1) {
        param.pageNum = 0;
        param.pageSize = 0;
      } else {
        param.pageNum = this.pageNum;
        param.pageSize = this.pageSize;
      }
      vList(param).then((res) => {
        if (res.code === 0) {
          console.log('列表', res.data.datas);
          this.videoList = res.data.datas;
          this.total = res.data.total;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        } else {
          this.loading = false;
        }
      }).catch((e) => this.$message.error('系统异常，请联系管理员'));
    },
  },
};
</script>

<style scoped lang="scss">
 .v-content{
   /*width: 1614px;*/
   position: relative;
   table {
     width:100%;
     position: relative;
      thead {
       tr{
         background: #E7EAEE;
         border: 1px solid #E8EBEE;
         box-sizing: border-box;
         border-bottom: 2px solid #31CBCF;
         th{
           border-left: 1px solid #ffffff;
           padding: 0 3px;
           height: 48px;
           box-sizing: border-box;
           vertical-align: middle;
           white-space: nowrap;
           font-size: 14px;
           font-weight: bold;
           color: #333333;
         }
       }

     }
      tbody{
       tr:nth-child(2n){
         background: #FFFFFF;
       }
       tr:hover{
         background: #F7F9FA;
       }
       tr{
         background: #F7F9FA;
         border: 1px solid #E8EBEE;
         box-sizing: border-box;
         td{
           border-left: 1px solid #E8EBEE;
           height:45px;
           font-size: 12px;
           text-align: center;
           box-sizing: border-box;
           vertical-align: middle;
           white-space: nowrap;
           p{
             max-width: 100%;
             white-space: nowrap;
             text-overflow: ellipsis;
             overflow: hidden;
             word-break: break-all;
             padding: 4px 5px;
             box-sizing: border-box;
           }
           .max-width{
             max-width: 380px;
             white-space: nowrap;
             text-overflow: ellipsis;
             overflow: hidden;
             word-break: break-all;
             text-align: center;
             padding: 0px 12px;
             box-sizing: border-box;
           }
         }
       }
     }
   }
   .vimg{
     width: 62px;
     height: 35px;
     object-fit: cover;
   }
   .dragsize{
     width: 16px;
     height: 14px;
     cursor: pointer;
   }
 }
 .release_btn{
   position: absolute;
   right: 23px;
   top: 17px;
   z-index: 33;
 }
  .relative{
    position: relative;
  }
   .w-48{
     width: 48px
   }
   .w-47{
     width: 47px;
   }
   .w-80{
     width: 80px;
   }
   .w-394{
     width:392px;
   }
   .w-136{
     width:136px;
   }
   .w-137{
      width:137px;
    }
   .w-160{
       width:160px;
     }
   .w-194{
        width:194px;
      }
  .no-data{
    min-height: 60px;
    text-align: center;
    width: 100%;
    display: flex;
    color: #909399;
    justify-content: center;
    align-items: center;
  }
 .move:hover {
   cursor: move;
 }
</style>
<style lang="scss">
  .v-content{
    .el-table .el-table__cell .cell{
      line-height: 1.4em !important;
    }
    .el-table .el-table__cell{
      padding: 0px 5px !important;
    }
    .dash{
      .z-btn {
        margin: 0 5px 0 6px;
        position: relative;
        &:after {
          position: absolute;
          content: '';
          width:1px;
          height: 14px;
          top: 50%;
          left: 0;
          margin:-7px 0 0 -6px;
          background: #EAEAEA;
        }
        &:first-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

</style>
