<template>
  <el-dialog
    custom-class="dialog-custom re"
    :append-to-body="true"
    :close-on-click-modal="false"
    :title="vtitle"
    :visible.sync="visible"
    :before-close="handleClose"
  >
    <div class="release">
      <div v-show="dragup">
        <el-upload
          action
          :http-request="Upload"
          name="video"
          class="upload-demo"
          drag
          multiple
          :before-upload="beforeUploadVideo"
          :on-change="uploadVideoChange"
          :show-file-list="false"
        >
          <div class="up_class">
            <i class="iconfont shangchuan upicon" /><span class="up_title">点击上传活拖拽视频到此区域</span>
            <div class="up_con">
              <p>1. 视频大小不能超过1G；</p>
              <p>2. 支持MP4、MOV、AVI、MKV格式；</p>
              <p>3. 请勿上传反动色情等违规视频；</p>
            </div>
          </div>
        </el-upload>
      </div>
      <div v-show="!dragup">
        <el-form
          ref="form"
          class="vo-class"
          :model="videoModel"
          label-width="97px"
          :rules="rules"
        >
          <el-form-item
            label="封面图"
            prop="scoverImgUrl"
            class="lin-15"
          >
            <span slot="label"><span class="redreq">*</span>封面图</span>
            <el-upload
              action
              :http-request="Upload2"
              name="video"
              :class="videoModel.scoverImgUrl?'hiddenborder upload-two':' upload-two'"
              drag
              multiple
              :before-upload="beforeUploadVideo2"
              :on-progress="uploadVideoProcess"
              :show-file-list="false"
            >
              <div
                v-if="!showcove"
                class="uimg-conent"
              >
                <div class="l-h-20">
                  <i class="iconfont shangchuantupian upicon" />
                </div>
                <div class="i-font">
                  上传图片
                </div>
              </div>
              <div
                v-else
                class="up_img"
              >
                <img :src="videoModel.scoverImgUrl">
                <span class="baclk_back" />
                <div class="icon_pos">
                  <div class="m-b-7">
                    <i class="iconfont xiugai white" />
                  </div>
                  <div>修改封面图</div>
                </div>
              </div>
            </el-upload>
            <div
              v-if="uploadLoading"
              class="cover_progess"
            >
              <el-progress
                type="circle"
                :percentage="coProgress"
              />
            </div>
            <div
              v-if="modfiyshow"
              class="progress"
            >
              <div class="viodname">
                {{ videoModel.svideoName }}
              </div>
              <el-progress
                :text-inside="true"
                :stroke-width="16"
                :percentage="progress"
              />
            </div>
          </el-form-item>
          <el-form-item
            label="视频名称"
            prop="svideoName"
          >
            <el-input
              v-model="videoModel.svideoName"
              placeholder="请输入视频名称"
              maxlength="20"
              show-word-limit
            />
          </el-form-item>
          <el-form-item
            label="视频描述"
            prop="svideoDescribe"
          >
            <el-input
              v-model="videoModel.svideoDescribe"
              placeholder="请输入视频描述"
              maxlength="20"
              show-word-limit
            />
          </el-form-item>
        </el-form>
        <div
          slot="footer"
          class="footer"
        >
          <z-button
            type="primary"
            margin="0 20px"
            :disabled="prdisabled"
            @click="push('form')"
          >
            {{ preserText }}
          </z-button>
          <z-button
            type="info"
            margin="0 20px"
            @click="handleClose"
          >
            取消
          </z-button>
        </div>
      </div>
    </div>
    <warm-pop
      :pop="pop.ispop"
      :is-cancle="true"
      :icon="-11"
      :title="pop.title"
      @close="close"
    >
      <z-button
        slot="btn"
        type="primary"
        @click="refreshList()"
      >
        确认
      </z-button>
    </warm-pop>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { getoss, addVideo } from '../../api/video';
import { client } from '../../api/ali-oss';
import warmPop from '../../components/common/warmPop';
import { deepClone } from '../../../public/js/util';

export default {
  name: 'ReleaseDialog',
  components: {
    warmPop,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    currentParam: {
      type: Object,
      default() { return {}; },
    },
  },
  computed: {
    ...mapGetters(['brandInfo']),
  },
  data() {
    const validateName = (rule, value, callback) => {
      console.log(value.trim().length <= 0);
      if (value.trim().length <= 0) {
        callback(new Error('视频名称不能为空'));
      } else {
        callback();
      }
    };
    const validateDescribe = (rule, value, callback) => {
      console.log(value.trim().length <= 0);
      if (value.trim().length <= 0) {
        callback(new Error('视频描述不能为空'));
      } else {
        callback();
      }
    };
    return {
      pop: {
        ispop: false,
        title: '该页面信息未保存，您确认要离开吗？',
      },
      proAut: {},
      uploadLoading: false,
      videoModel: {
        svideoUrl: '', // 视频url
        scoverImgUrl: '',
        svideoName: '',
        svideoDescribe: '',
        svideoFormat: '',
        svideoDuration: '',
        svideoSize: '',
        pkid: 0,
      },
      vtitle: '',
      preserText: '',
      rules: {
        scoverImgUrl: [{
          validator(rule, value, callback) {
            if (!value) callback(false);
            else callback();
          },
          message: '视频封面不能为空！',
          trigger: 'change',
        }],
        svideoName: [
          { required: true, message: '请输入视频名称！', trigger: 'change' },
          { validator: validateName, trigger: 'blur' },
        ],
        svideoDescribe: [
          { required: true, message: '请输入视频描述！', trigger: 'change' },
          { validator: validateDescribe, trigger: 'blur' },
        ],
      },
      progress: 0,
      coProgress: 0,
      showcove: false,
      dragup: true,
      prdisabled: true,
      modfiyshow: true,
      nosaveshow: false,
      nindex: 1,
    };
  },
  watch: {
    videoModel: {
      handler(newVal) {
        // debugger;
        if (this.videoModel.svideoUrl && this.videoModel.scoverImgUrl && this.videoModel.svideoName && this.videoModel.svideoDescribe && this.videoModel.svideoFormat) {
          if (this.videoModel.svideoName.length <= 20 && this.videoModel.svideoDescribe.length <= 20) {
            this.prdisabled = false;
          } else {
            this.prdisabled = true;
          }
        } else {
          this.prdisabled = true;
        }
        // debugger;
        if (this.videoModel.svideoUrl || this.videoModel.scoverImgUrl || this.videoModel.svideoName || this.videoModel.svideoDescribe || this.videoModel.svideoFormat) {
          if (this.nindex === 1) {
            this.nosaveshow = false;
          } else {
            this.nosaveshow = true;
          }
          this.nindex += 1;
        } else {
          this.nosaveshow = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    console.log('22', this.getHMS(5555));
    this.getproauthor();
    if (this.currentParam.pkid) {
      this.vtitle = '修改视频';
      this.preserText = '保存';
      this.dragup = false;
      const param = deepClone(this.currentParam);
      this.videoModel = {
        svideoUrl: param.svideoUrl, // 视频url
        scoverImgUrl: param.scoverImgUrl,
        svideoName: param.svideoName,
        svideoDescribe: param.svideoDescribe,
        svideoFormat: param.svideoFormat,
        svideoDuration: param.svideoDuration,
        svideoSize: param.svideoSize,
        pkid: param.pkid,
      };
      this.showcove = true;
      this.modfiyshow = false;
    } else {
      this.vtitle = '发布视频';
      this.preserText = '发布';
      this.videoModel = {
        svideoUrl: '', // 视频url
        scoverImgUrl: '',
        svideoName: '',
        svideoDescribe: '',
        svideoFormat: '',
        svideoDuration: '',
        svideoSize: '',
        pkid: 0,
      };
      this.dragup = true;
      this.showcove = false;
      this.modfiyshow = true;
    }
    // console.log(this.getHMS(18.9099089));
  },
  methods: {
    close() {
      this.pop.ispop = false;
    },
    refreshList() {
      this.pop.ispop = false;
      this.$emit('update:visible', false);
    },
    handleClose() {
      if (this.nosaveshow) {
        this.pop.ispop = true;
      } else {
        this.$emit('update:visible', false);
      }
    },
    getproauthor() {
      getoss().then((res) => {
        if (res.code === 0) {
          this.proAut = res.data;
        }
        // console.log(this.proAut);
      }).catch((e) => this.$message.error('系统异常，请联系管理员'));
    },
    // 上传视频验证
    beforeUploadVideo(file) {
      debugger;
      console.log(file.type);
      const isLt10M = file.size / 1024 / 1024 < 1024;
      if (['video/mp4', 'video/quicktime', 'video/avi', 'video/x-matroska'].indexOf(file.type) === -1) {
        this.$message.error('请上传正确的视频格式');
        return false;
      }
      if (!isLt10M) {
        this.$message.error('上传视频大小不能超过1G哦!');
        return false;
      }
      this.videoModel.svideoSize = (file.size / 1024 / 1024).toFixed(2);
    },
    // 上传封面图验证
    beforeUploadVideo2(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      const limitStr = ['《', '》', '%', '#', '*', ';', '[', ']', '【', '】', '{', '}', '&', '$'];
      const bool = !limitStr.find((x) => file.name.indexOf(x) > -1);
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!');
      }
      if (!bool) {
        this.$message.error('图片命名不得包含非法字符!');
      }
      return isLt2M && bool;
    },
    // 上传之前
    uploadVideoProcess(event, file, fileList) {
      // debugger;
      this.uploadLoading = true;
    },
    uploadVideoChange(file, fileList) {
      // debugger;
      // 防止触发两次
      if (file.status !== 'ready') return;
      const getVideoInfo = new Promise((resolve) => {
        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(file.raw);
        videoElement.addEventListener('loadedmetadata', () => {
          resolve({
            duration: videoElement.duration,
          });
        });
      });
      getVideoInfo.then((videoInfo) => {
        if (videoInfo) {
          // debugger;
          const duration = this.getHMS(videoInfo.duration);
          this.videoModel.svideoDuration = duration;
        }
      });
    },
    Upload(file) {
      this.dragup = false;
      // debugger;
      let config = {};
      if (this.proAut) {
        // config = {
        //   region: 'oss-cn-shenzhen', // 后台给
        //   accessKeyId: 'LTAISfE7Ka1SOQO8', // 后台给
        //   accessKeySecret: 'tdvMcDaZ3rpZSRXTCiqdLBXfOGsPgG', // 后台给
        //   bucket: 'zydmall-test',
        // };
        // stsToken: 'NXllCGAc+rCLzkf0qv3sBh0v4L/bh10SUTbLnA2lArfixyjIJO4vi43kEseANNdzCg8',
        config = {
          region: this.proAut.region, // 后台给
          accessKeyId: this.proAut.accessKeyId, // 后台给
          accessKeySecret: this.proAut.accessKeySecret, // 后台给
          bucket: this.proAut.bucket,
          stsToken: this.proAut.stsToken,
        };
      }
      console.log(config);
      const that = this;
      async function multipartUpload() {
        // debugger;
        const temporary = file.file.name.lastIndexOf('.');
        const fileNameLength = file.file.name.length;
        const svideoName = file.file.name.substring(
          0,
          temporary,
        );// 视频格式
        const svideoFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength,
        );// 视频名字
        that.videoModel.svideoFormat = svideoFormat;
        that.videoModel.svideoName = svideoName;
        // debugger
        // let fileName = getFileNameUUID() + "." + fileFormat;
        const fileName = file.file.name;
        client(config)
          .multipartUpload(`videoTest/${fileName}`, file.file, {
            progress(p) {
              // p进度条的值
              // console.log(p);
              // that.showProgress = true;
              that.progress = Math.floor(p * 100);
            },
          })
          .then((result) => {
            // 上传成功返回值，可针对项目需求写其他逻辑
            // debugger;
            console.log('返回2', result.res.requestUrls[0]);
            // eslint-disable-next-line eqeqeq
            if (result.res.requestUrls[0].indexOf('?uploadId') != -1) {
              const imgurl = that.getCaption(result.res.requestUrls[0], '?uploadId');
              that.videoModel.svideoUrl = imgurl;
            } else {
              // eslint-disable-next-line prefer-destructuring
              that.videoModel.svideoUrl = result.res.requestUrls[0];
            }
          })
          .catch((err) => {
            that.$message.error('上传失败，请联系管理员');
            console.log('err:', err);
          });
      }
      multipartUpload();
    },
    Upload2(file) {
      this.uploadLoading = true;
      let config = {};
      if (this.proAut) {
        config = {
          region: this.proAut.region, // 后台给
          accessKeyId: this.proAut.accessKeyId, // 后台给
          accessKeySecret: this.proAut.accessKeySecret, // 后台给
          bucket: this.proAut.bucket,
          stsToken: this.proAut.stsToken,
        };
      }
      console.log(config);
      const that = this;
      that.showcove = true;
      async function multipartUpload() {
        const temporary = file.file.name.lastIndexOf('.');
        const fileNameLength = file.file.name.length;
        const fileFormat = file.file.name.substring(
          temporary + 1,
          fileNameLength,
        );
        // let fileName = getFileNameUUID() + "." + fileFormat;
        const fileName = file.file.name;
        client(config)
          .multipartUpload(`videoTest/${fileName}`, file.file, {
            progress(p) {
              // p进度条的值
              console.log(p);
              // that.showProgress = true;
              that.coProgress = Math.floor(p * 100);
            },
          })
          .then((result) => {
            // 上传成功返回值，可针对项目需求写其他逻辑
            console.log('返回2', result.res.requestUrls[0]);
            // eslint-disable-next-line eqeqeq
            if (result.res.requestUrls[0].indexOf('?uploadId') != -1) {
              const imgurl = that.getCaption(result.res.requestUrls[0], '?uploadId');
              that.videoModel.scoverImgUrl = imgurl;
            } else {
              that.videoModel.scoverImgUrl = result.res.requestUrls[0];
            }
            that.uploadLoading = false;
          })
          .catch((err) => {
            that.$message.error('上传失败，请联系管理员');
            console.log('err:', err);
          });
      }
      multipartUpload();
    },
    // 截取指定字符后的内容
    getCaption(url, parameter) {
      const index = url.indexOf(parameter);
      const url2 = url.substring(0, index);
      return url2;
    },
    // 根据秒数转换成对应的时分秒
    getHMS(s) {
      // debugger
      const s1 = parseInt(s, 10);
      const day = Math.floor(s1 / (24 * 3600)); // Math.floor()向下取整
      const hour = Math.floor((s1 - day * 24 * 3600) / 3600);
      const minute = Math.floor((s1 - day * 24 * 3600 - hour * 3600) / 60);
      const second = s1 - day * 24 * 3600 - hour * 3600 - minute * 60;
      // debugger;
      if (hour) {
        return `${hour}:${minute}:${second}`;
      }
      // debugger
      if (second < 10) {
        if (minute < 10) {
          return `0${minute}:0${second}`;
        }
        return `${minute}:${second}0`;
      }
      return `0${minute}:${second}`;
    },
    push(formName) {
      if (!this.videoModel.svideoUrl) {
        this.$message.error('视频还未上传成功');
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 新建或更新
          if (this.videoModel.pkid === 0) {
            this.newVideo();
          } else {
            this.updataVideo();
          }
        }
      });
    },
    newVideo() {
      const param = {
        nbrandId: this.brandInfo.nbrandId,
        ntype: 1,
        svideoDuration: this.videoModel.svideoDuration,
        svideoSize: `${this.videoModel.svideoSize}MB`,
        sbrandName: this.brandInfo.sbrandName,
        scoverImgUrl: this.videoModel.scoverImgUrl,
        svideoDescribe: this.videoModel.svideoDescribe,
        svideoFormat: this.videoModel.svideoFormat,
        svideoName: this.videoModel.svideoName,
        svideoUrl: this.videoModel.svideoUrl,
      };
      addVideo(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('发布成功');
          this.$emit('refreshList');
        } else {
          this.$message.error('发布失败，请联系管理员');
        }
      }).catch((e) => this.$message.error(e.message));
    },
    updataVideo() {
      const param = {
        nbrandId: this.brandInfo.nbrandId,
        ntype: 1,
        svideoDuration: this.videoModel.svideoDuration,
        svideoSize: this.videoModel.svideoSize,
        sbrandName: this.brandInfo.sbrandName,
        scoverImgUrl: this.videoModel.scoverImgUrl,
        svideoDescribe: this.videoModel.svideoDescribe,
        svideoFormat: this.videoModel.svideoFormat,
        svideoName: this.videoModel.svideoName,
        svideoUrl: this.videoModel.svideoUrl,
        pkid: this.videoModel.pkid,
      };
      addVideo(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('修改成功');
          this.$emit('refreshList');
        } else {
          this.$message.error('修改失败，请联系管理员');
        }
      }).catch((e) => this.$message.error(e.message));
    },
  },
};
</script>

<style lang="scss">
  .re{
    .footer{
      .z-btn{
        margin: 0px 12px!important;
      }
    }
    .el-input__suffix{
      right: 12px!important;
    }
    .el-dialog__body{
      padding: 37px 40px 50px!important;
    }
    .el-upload-dragger:hover{
      border-color: #19B0B0!important;
    }
    .el-upload-dragger{
      border: 1px dashed  #19B0B0!important;
    }
  }
  .release{
    .upload-demo{
      .el-upload-dragger{
        width: 528px;
        height: 208px;
      }
    }
    .upload-two{
      .el-upload-dragger{
        width: 142px;
        height: 80px;
      }
    }
    .hiddenborder{
      .el-upload-dragger{
       border: none!important;
      }
    }
    .upicon{
      font-size: 26px;
      top: 2px;
      position: relative;
      margin-right: 6px;
      color: #19B0B0;
    }
    .up_class{
      padding:55px 0px 0px;
      position: relative;
      .up_title{
        font-size: 16px;
        font-weight: bold;
        color: #19B0B0;
        line-height: 17px;
      }
      .up_con{
        margin-top: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        text-align: left;
        padding-left: 147px;
      }
    }
    .uimg-conent{
      position: relative;
      padding: 22px 0;
      box-sizing: border-box;
      .l-h-20{
        line-height: 20px;
      }
      .i-font{
        font-size: 12px;
        font-weight: 400;
        color: #19B0B0;
        line-height: 11px;
        margin-top: 5px;
      }
    }
    .footer{
      text-align: center;
      margin-top: 42px;
    }
    .up_img{
      width: 142px;
      height: 80px;
      img{
        width: 100%;
        height: 100%;
      }
      .baclk_back{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .6);
      }
      .icon_pos{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        .white{
          color: #FFFFFF;
          font-size: 22px;
          margin-bottom: 7px;
        }
      }
    }
  }
  .vo-class{
    .el-input__inner{
      width: 426px;
      height: 28px;
      padding: 0 12px;
    }
    .el-form-item__label{
      padding: 0 22px 0 0!important;
      line-height: 27px!important;
    }
    .el-form-item__content{
      line-height: 26px!important;
    }
    .el-form-item{
      margin-bottom: 19px!important;
    }
    .lin-15{
      .el-form-item__content{
        line-height: 14px !important;
      }
      .el-form-item__label{
        padding: 0 22px 0 0!important;
      }
    }
    .progress{
      position: absolute;
      left: 157px;
      width: 265px;
      height: 80px;
      top: 0px;
      .viodname{
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-top: 16px;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        margin-bottom: 13px;
      }
      .el-progress-bar__inner{
        background: linear-gradient(-22deg, #73E2E2 0%, #28CCCC 100%);
      }
      .el-progress-bar__outer{
        background-color: #F1F1F1;
      }
      .el-progress-bar__innerText{
        color:black!important;
      }
    }
    .cover_progess{
      position: absolute;
      top: 0;
      left: 8%;
      .el-progress-circle{
        width: 80px!important;
        height: 80px!important;
      }
    }
  }
  .m-b-7{
    margin-bottom: 7px;
  }
  .redreq{
    color: #F56C6C;
    margin-right: 4px;
  }
</style>
