<template>
  <el-dialog
    custom-class="dialog-custom palydialog"
    :append-to-body="true"
    :close-on-click-modal="false"
    :title="svideoname"
    :visible.sync="visible"
    :before-close="handleClose"
  >
    <div class="play_content">
      <div class="video-component">
        <video
          id="video"
          controls="controls"
          :src="svideoUrl"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { deepClone } from '../../../public/js/util';
import { addPlayNum, addVideo } from '../../api/video';

export default {
  name: 'PlayDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    playParam: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      svideoUrl: '',
      svideoname: '',
    };
  },
  mounted() {
    if (this.playParam) {
      const param = deepClone(this.playParam);
      this.svideoUrl = param.svideoUrl;
      this.svideoname = param.svideoName;
      // addPlayNum(param.pkid).then((res) => {
      //   if (res.code === 0) {
      //     console.log(res);
      //   }
      // });
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false);
    },
  },
};
</script>

<style lang="scss">
  .palydialog{
    width: 744px!important;
    .el-dialog__body{
      height: 420px;
      box-sizing: border-box!important;
      overflow: hidden!important;
      margin-bottom: 16px;
    }
    .play_content{
       width:675px;
       height: 400px;
      .video-component{
        width: 100%;
        height: 100%;
        background: #000;
        padding: 4px;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        video{
          width: 100%;
          height: 100%;
        }
      }
    }

  }
</style>
